.wcn-header.mui-fixed { background: white !important; color: black !important; width: calc(100% - 175px); margin-left: 175px; }
.wcn-header__google > * { box-sizing: unset;}
.wcn-header__google .wcn-gsignin { display: none; }
.wcn-header__image { width: 40px; padding: 15px; padding-bottom: 5px; padding-top: 5px; cursor: pointer;}
.wcn-header__image img { width: 100%; border-radius: 50px; }

.wcn-button__logout { border-radius: 3px; font-size: 1rem; padding: 10px 30px; border-color: #dedede; }

/************************************ Account Modal *******************/
.wcn-account__modal { display: flex; background: #fff; border: 1px solid #ccc; border-color: rgba(0,0,0,.2); color: #000; box-shadow: 0 2px 10px rgba(0,0,0,.2); position: absolute; right: 0px; top: 44px; margin-left: 12px; border-radius: 8px; width: 350px; height: 400px; flex-direction: column; justify-content: center; align-items: center;}
