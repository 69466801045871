.wcn-registerpage { background: url("https://navenpillai.com/wp-content/uploads/2019/02/Guide-to-Google-My-Business-2019.jpg"); background-repeat: no-repeat; background-size: cover; min-height: 100vh; display: flex; }

/******************************************* FORM **********************************/
.wcn-register-form__input     { border-radius: 5px; width: 90%; padding: 10px 10px; margin: 10px 0px; background: #dcdcdcad; color: black; border-width: 1.45px; border-color: #bdbdbd;  border-style: solid; color: #2b2b2bf5; font-size: 1.2rem; }
.wcn-register-form__submit    { background-color: #5555ff; color: white; font-size: 1rem; font-weight: 700; width: 94%; margin: 10px 0; padding: 15px 10px; border-radius: 5px; }


/******************************************* MODAl **********************************/
.wcn-register__modal              { display: flex; flex-direction: column; align-items: center; background: white; box-shadow: -1px 4px 20px 1px rgba(37, 37, 37, 0.22); margin: auto; border-radius: 10px; width: 30vw;  position: relative; top: 10%; width: 30vw; height: 55vh; }
.wcn-register__modal__submitted   { display: flex; flex-direction: column; align-items: center; background: white; box-shadow: -1px 4px 20px 1px rgba(37, 37, 37, 0.22); margin: auto; border-radius: 10px; width: 30vw;  position: relative; top: 10%; width: 30vw; height: 20vh; padding: 30px 30px; justify-content: center; }
.wcn-register__modal__submitted a { text-align: center; text-decoration: none;}
