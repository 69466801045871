
.wcn-review__increase_icon { color: green; }

.table-header { display: flex; justify-content: space-between; }
.wcn-table__wrapper th { white-space: nowrap; }

.location-rating { display: flex; background: #ea7600; color: #fff; font-weight: 700; width: 30px; height: 30px; border-radius: 40px; align-items: center; justify-content: center; text-align: center; margin-left: auto; }

.rating-0 { background: #000; }
.rating-1 { background: #FF0000; }
.rating-2 { background: #ff7f00; color: #000; }
.rating-3 { background: #FFFF00; color: #000; }
.rating-4 { background: #7FFF00; color: #000; }
.rating-5 { background: #00FF00; color: #000; }

.wcn-page__header { background: #fff; border: 1px solid #e2e2e2; padding: 15px; margin-bottom: 10px; }
.wcn-page__header h6 { flex-grow: 1; flex-shrink: 0; padding-right: 20px;  }
.wcn-page__header .wcn-datepicker { justify-self: flex-end; }

.wcn-location__statstics { padding-top: 20px; padding-bottom: 20px; }
.location-review-count { margin-bottom: 20px; }
.location-review-count h3 { margin: 0; }