@import './loaders';
@import './Public/homepage';
@import './Public/registerpage';
@import './header';

* { box-sizing: border-box; }
html, body { height: 100%; }
body { margin: 0; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; }
a { color: #1e9be9; }
#root { min-height: 100%; }

.App { text-align: center; min-height: 100vh;  }
.wcn-gmb-body { min-height: 100vh; }

.wcn-user__image img{ border-radius: 50px; }

.logo { margin-right: 20px; }