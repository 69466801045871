/************************************ Sidebar Wrapper *******************/
.wcn-gmb__sidebar,
.wcn-gmb__sidebar .MuiDrawer-paper { min-width: 175px; }
.wcn-sidebar__item { padding: 0; }

/************************************ Sidebar icon *******************/
.wcn-sidebar__icon { margin-right: 20px; font-size: 1.5rem; color: #292929; margin-left: 20px; }

/************************************ Sidebar Item *******************/
.wcn-sidebar__link { display: block; text-decoration: none; color: inherit; padding-top: 5px; padding-bottom: 5px; }
.wcn-sidebar__link:hover .wcn-sidebar__item{ border-top-right-radius: 35px; border-bottom-right-radius: 35px; background-color: #f5f5f5;}
.wcn-sidebar__link:hover .wcn-sidebar__item.selected { border-top-right-radius: 35px; border-bottom-right-radius: 35px; background-color: #e6e6e6;}

.wcn-sidebar__item.selected {  border-top-right-radius: 35px; border-bottom-right-radius: 35px; background-color: #e6e6e6;}
.wcn-sidebar__item.selected .wcn-sidebar__item__title { padding-right: 40px; }