.wcn-card__reviewer__wrapper { overflow: visible !important; margin-bottom: 20px; }
.wcn-review-card__header { display: flex; align-items: center; padding-bottom: 10px; margin-bottom: 10px; border-bottom: 1px solid #e2e2e2; }
.wcn-review-card__reviewer_info { display: flex; align-items: center; }
.wcn-review-card__name { padding-left: 15px; }
.wcn-review-card__rating { justify-content: flex-end; flex-grow: 1; flex-shrink: 0; display: flex; align-items: center; }
.rating-value { font-size: 1.8rem; color: #484848; }

.wcn-review-card__comment p { padding-top: 6px; padding-bottom: 15px; }

.wcn-page__reviews { display: block;}
.wcn-reviews__pagination {  overflow: unset !important; float: right; padding-right: 40px; }

.wcn-datepicker-btn__apply { margin-top: 10px !important; }

.wcn-review__footer { display: flex; justify-content: space-between; }
.wcn-review__date { color: #999; display: block; }

.review-card__count { font-size: 1.8rem; }
.review-card__rating { font-size: 1.8rem; }

.wcn-review-new { background: #7FFF00; padding: 4px 8px; border-radius: 10px; margin-right: 10px; }

.wcn-card__reviewer__wrapper--rating-1 { border: 1px solid #FF0000; }
.wcn-card__reviewer__wrapper--rating-2 { border: 1px solid #ff7f00; }
.wcn-card__reviewer__wrapper--rating-3 { border: 1px solid #FFFF00; }
.wcn-card__reviewer__wrapper--rating-4 { border: 1px solid #7FFF00; }
.wcn-card__reviewer__wrapper--rating-5 { border: 1px solid #00FF00; }

.reviews-toolbar,
.wcn-reviews__pagination { background: #fff; border: 1px solid #e2e2e2; margin-bottom: 20px;  }